export default class HeadScroll {
  constructor(body) {
    this.body = body
    this.init()
  }

  init() {
    this.pageFlag = this.body.classList.contains('page-index')
    const setPosition =
      document.querySelector(this.pageFlag ? '.kv' : '.mainView').clientHeight - 80
    window.addEventListener('scroll', () => {
      const scrollPosi = document.documentElement.scrollTop || document.body.scrollTop
      if (setPosition <= scrollPosi) {
        this.body.classList.add('is-headBlack')
      } else {
        this.body.classList.remove('is-headBlack')
      }
    })
  }
}
