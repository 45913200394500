export default class SideScroll {
  constructor(body, topTarget) {
    this.body = body
    this.init(topTarget)
  }

  init(topTarget) {
    const setPosition =
      topTarget === '.kv'
        ? document.querySelector(topTarget).clientHeight +
          document.querySelector('.indexNav').clientHeight
        : document.querySelector(topTarget).clientHeight
    window.addEventListener('scroll', () => {
      const scrollPosi = document.documentElement.scrollTop || document.body.scrollTop

      if (setPosition <= scrollPosi) {
        this.body.classList.add('is-sideFixed')
      } else {
        this.body.classList.remove('is-sideFixed')
      }
    })
  }
}
