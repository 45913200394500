import Swiper from 'swiper/bundle'
import lottie from 'lottie-web'
import smoothscroll from 'smoothscroll-polyfill'
import SideScroll from './modules/sideScroll'
import HeadScroll from './modules/headScroll'
import SpBottomReserve from './modules/spBottomReserve'
import ConceptSlider from './modules/conceptSlider'

const commonUtil = {
  ua: {
    browser() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      let ua = ''
      if (userAgent.indexOf('opera') !== -1) {
        ua = 'opera'
      } else if (userAgent.indexOf('edge') !== -1) {
        ua = 'edge'
      } else if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
        ua = 'ie'
      } else if (userAgent.indexOf('chrome') !== -1) {
        ua = 'chrome'
      } else if (userAgent.indexOf('safari') !== -1) {
        ua = 'safari'
      } else if (userAgent.indexOf('firefox') !== -1) {
        ua = 'firefox'
      } else if (userAgent.indexOf('gecko') !== -1) {
        ua = 'gecko'
      }
      return ua
    },
    os() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      let ua = ''
      if (userAgent.indexOf('iphone') !== -1) {
        ua = 'iphone'
      } else if (userAgent.indexOf('ipad') !== -1) {
        ua = 'ipad'
      } else if (userAgent.indexOf('android') !== -1) {
        ua = 'android'
      } else if (userAgent.indexOf('windows') !== -1) {
        ua = 'win'
      } else if (userAgent.indexOf('macintosh') !== -1) {
        ua = 'mac'
      }
      return ua
    },
  },
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.pageRoot = document.querySelector('main.contents')
    this.topFlag = document.querySelector('body').classList.contains('page-index')
    this.init()

    lottie
      .loadAnimation({
        container: document.querySelector('#lottie'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/wp-content/themes/en-michi_v4/assets/json/loading_BK.json',
      })
      .play()
  }

  init() {
    this.initPages()
  }

  initPages() {
    // 全ページ共通適用
    this.body.classList.add(`is-${commonUtil.ua.browser()}`)
    this.body.classList.add(`is-${commonUtil.ua.os()}`)
    this.head = new HeadScroll(this.body)
    this.side = new SpBottomReserve(this.body)

    // ナビゲーション開閉
    document.querySelector('.navTrigger').addEventListener('click', () => {
      this.body.classList.toggle('view-nav')
    })

    // ナビゲーションを押下時に閉じる
    Array.from(document.querySelectorAll('#gNav .siteMenu a')).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        document.querySelector('body').classList.toggle('view-nav')
      })
    })

    // 言語スイッチャー押下
    Array.from(document.querySelectorAll('.langTrigger')).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        trigger.classList.toggle('is-open')
      })
    })

    Array.from(document.querySelectorAll('.snsTrigger')).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        Array.from(document.querySelectorAll('.snsTrigger')).forEach((trigger2) => {
          if (trigger !== trigger2) {
            trigger2.classList.remove('is-open')
          }
        })
        trigger.classList.toggle('is-open')
      })
    })

    // SP版 カテゴリー セレクト
    if (document.querySelector('.categorySelect select')) {
      document.querySelector('.categorySelect select').addEventListener('change', (select) => {
        window.location.href = select.target.value
      })
    }

    // トップページのみ適用
    if (this.topFlag) {
      this.sideReserve = new SideScroll(this.body, '.kv')
    } else {
      this.sideReserve = new SideScroll(this.body, '.mainView')
    }

    // スライドショー
    // this.swiper = new Swiper('.swiper-container', {
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    //   },
    //   navigation: {
    //     hideOnClick: true,
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    //   thumbs: {
    //     slideThumbActiveClass: 'is-current',
    //     thumbsContainerClass: 'conceptThumbList',
    //   },
    // })

    const swiperContainers = document.querySelectorAll('[data-swiper]')

    swiperContainers.forEach((container, index) => {
      const swiper = new Swiper(container, {
        pagination: {
          el: container.querySelector('.swiper-pagination'),
          clickable: true,
        },
        navigation: {
          nextEl: container.querySelector('.swiper-button-next'),
          prevEl: container.querySelector('.swiper-button-prev'),
          hideOnClick: true,
        },
      })
    })

    const singleGallery = document.querySelectorAll('.blocks-gallery-grid')

    let singleSwiper
    if (singleGallery) {
      Array.from(singleGallery).forEach((slide, idx) => {
        slide.innerHTML = `<div class="swiper-wrapper">${slide.innerHTML}</div><div class="swiper-pagination"></div><div class="swiper-button-next"></div><div class="swiper-button-prev"></div>`
        setTimeout(() => {
          singleSwiper[idx] = new Swiper('.blocks-gallery-grid', {
            slideClass: 'blocks-gallery-item',
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              hideOnClick: true,
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          })
        }, 50)
      })
    }

    if (document.querySelector('body').classList.contains('page-index')) {
      const conceptSlide = new ConceptSlider(
        '.conceptSlide',
        '.conceptSlideImg',
        '.conceptThumbList',
      ) // eslint-disable-line no-undef, no-unused-vars, no-new
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.enmichi = new App()
  Array.from(document.querySelectorAll('.js-show-tripla')).forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault()
      document.querySelector('html').classList.toggle('is-tripla-view')
    })
  })

  window.addEventListener('click', (e) => {
    const { target } = e
    if (target.id === 'tripla-searching-app') {
      document.querySelector('html').classList.toggle('is-tripla-view')
    }
  })
})

let topFlag = false
const tag = document.createElement('script')
const firstScriptTag = document.getElementsByTagName('script')[0]
tag.src = 'https://www.youtube.com/iframe_api'
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

function onPlayerReady(event) {
  event.target.mute()
  event.target.playVideo()
}

function onPlayerStateChange(event) {
  const ytStatus = event.target.getPlayerState()
  if (ytStatus === YT.PlayerState.ENDED) {
    // eslint-disable-line no-undef
    event.target.mute()
    event.target.playVideo()
  }
}

function screenFit() {
  const youtubeMask = document.querySelector('.bg-youtube')
  const youtubePlayer = document.querySelector('#yt_player')

  const movieBaseWidth = youtubeMask.clientWidth + 100
  const movieBaseHeight = youtubeMask.clientHeight + 100
  let movieWidth = 560
  let movieHeight = 315
  const rate = Math.max(movieBaseHeight / movieHeight, movieBaseWidth / movieWidth)

  movieWidth = 560 * rate
  movieHeight = 315 * rate

  youtubePlayer.style.width = `${movieWidth}px`
  youtubePlayer.style.height = `${movieHeight}px`
  youtubePlayer.style.top = `${(movieHeight - (movieBaseHeight - 100)) * -0.5}px`
  youtubePlayer.style.left = `${(movieWidth - (movieBaseWidth - 100)) * -0.5}px`
}

window.onload = function loaded() {
  topFlag = document.querySelector('body').classList.contains('page-index')
  smoothscroll.polyfill()
  Array.from(document.querySelectorAll('.js-smooth-scroll')).forEach((link) => {
    link.addEventListener('click', (e) => {
      const ankerTarget = e.target
      e.preventDefault()
      document.querySelector('body').classList.remove('view-nav')
      const targetId = ankerTarget.hash
      const targetElement = document.querySelector(targetId)
      const rectTop = targetElement.getBoundingClientRect().top
      const offsetTop = window.pageYOffset
      const buffer = 0
      const top = rectTop + offsetTop - buffer
      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    })
  })

  // トップページのみ適用
  if (topFlag) {
    setTimeout(() => {
      document.querySelector('.openLoader').classList.add('is-hidden')
    }, 2000)

    const topYoutubeID = topYoutube || '' // eslint-disable-line no-undef
    const player = new YT.Player('yt_player', {
      // eslint-disable-line no-undef, no-unused-vars
      videoId: topYoutubeID,
      playerVars: {
        playsinline: 1,
        loop: 1,
        listType: 'playlist',
        playlist: topYoutubeID,
        rel: 0,
        controls: 0,
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    })
    screenFit()
    window.addEventListener('resize', screenFit)

    const modal = document.querySelector('.modalMovie')
    const modalBtn = document.querySelector('.kvFullMovie button')
    const modalClose = document.querySelector('.modal-close')
    modalBtn.addEventListener('click', (event) => {
      event.preventDefault()
      modal.classList.add('is-active')
    })
    modalClose.addEventListener('click', (event) => {
      event.preventDefault()
      modal.classList.remove('is-active')
    })
  } else {
    document.querySelector('.openLoader').classList.add('is-hidden')
  }
}
