export default class SpBottomReserve {
  constructor(body) {
    this.body = body
    this.init()
  }

  init() {
    const setPosition = document.querySelector('html').clientHeight
    window.addEventListener('scroll', () => {
      const scrollPosi = document.documentElement.scrollTop || document.body.scrollTop
      if (setPosition <= scrollPosi) {
        this.body.classList.add('is-reserveFixed')
      } else {
        this.body.classList.remove('is-reserveFixed')
      }
    })
  }
}
