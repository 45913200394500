import smoothscroll from 'smoothscroll-polyfill'

export default class conceptSlider {
  constructor(target, img, thumbnails) {
    smoothscroll.polyfill()
    this.target = target
    // 初期値
    this.slide = {
      dom: document.querySelector(this.target),
      imgWrap: document.querySelector(img),
      img: document.querySelector(`${img} > .img`),
      current: Number(document.querySelector(img).getAttribute('data-current')),
      count: {
        value: 1,
        dom: document.querySelector(`${img} > .count`),
      },
      btn: {
        next: document.querySelector(`${img} > .btn-next`),
        prev: document.querySelector(`${img} > .btn-prev`),
      },
      thumbs: document.querySelector(`${thumbnails}`),
      thumb: document.querySelectorAll(`${thumbnails} .thumb`),
      length: Array.from(document.querySelectorAll(`${thumbnails} .thumb`)).length,
    }
    this.init()
  }

  init() {
    this.slide.dom.querySelectorAll('button').forEach((btn) => {
      btn.addEventListener('click', () => {
        const type = btn.classList.contains('btn-next') ? 'next' : 'prev'
        this.slide.current =
          type === 'next' && this.slide.length > this.slide.current
            ? this.slide.current + 1
            : this.slide.current - 1 // 次の写真番号を代入
        this.slideChange()
      })
    })
    this.slide.thumb.forEach((img) => {
      img.addEventListener('click', () => {
        this.slide.current = Number(img.getAttribute('data-index')) // 次の写真番号を代入
        this.slideChange()
      })
    })
    // スライドスワイプ
    this.setSwipe(this.slide.dom)

    this.setFillHeight()
    window.addEventListener('resize', () => {
      this.setFillHeight()
    })
  }

  /**
   * CSS サムネイル高さ指定セット
   */
  setFillHeight() {
    const thumbHeight = this.slide.thumb[0].clientHeight
    document.documentElement.style.setProperty('--thumbHeight', `${thumbHeight}px`)
  }

  /**
   * スライドショー変更関数
   */
  slideChange() {
    // this.slide.count.dom.find('.current').html(this.slide.current); // 現在の写真番号を差し替え

    // prev/nextボタンを表示指定
    if (this.slide.length === this.slide.current) {
      this.slide.btn.next.classList.add('is-disabled')
      this.slide.btn.prev.classList.remove('is-disabled')
    } else if (this.slide.current === 1) {
      this.slide.btn.prev.classList.add('is-disabled')
      this.slide.btn.next.classList.remove('is-disabled')
    } else {
      this.slide.btn.next.classList.remove('is-disabled')
      this.slide.btn.prev.classList.remove('is-disabled')
    }

    // サムネイルをアクティブ化
    let nextImg = ''

    this.slide.thumb.forEach((thumb, i) => {
      if (this.slide.current === i + 1) {
        thumb.classList.add('is-selected')
        nextImg = thumb.getAttribute('data-img')
      } else {
        thumb.classList.remove('is-selected')
      }
    })

    // メインイメージの変更
    const newImg = document.createElement('img')
    newImg.setAttribute('class', 'img')
    newImg.setAttribute('src', nextImg)
    this.slide.imgWrap.prepend(newImg)
    const prevImg = this.slide.imgWrap.querySelector('.img:nth-of-type(2)')
    prevImg.classList.add('fadeOut') // .3s
    setTimeout(() => {
      prevImg.remove()
    }, 300)
  }

  /**
   * スワイプ時の画像変更
   * @param elem
   */
  setSwipe(elem) {
    const t = elem
    const dist = 30 // スワイプを感知する最低距離（ピクセル単位）
    let startX = 0 // タッチ開始 x座標
    // let startY = 0; // タッチ開始 y座標
    let moveX = 0 // スワイプ中の x座標
    // let moveY = 0; // スワイプ中の y座標

    // タッチ開始時： xy座標を取得
    t.addEventListener('touchstart', (e) => {
      // e.preventDefault();
      startX = e.touches[0].pageX
      // startY = e.touches[0].pageY;
    })

    // スワイプ中： xy座標を取得
    t.addEventListener('touchmove', (e) => {
      // e.preventDefault();
      moveX = e.changedTouches[0].pageX
      // moveY = e.changedTouches[0].pageY;
    })

    // タッチ終了時： スワイプした距離から左右どちらにスワイプしたかを判定する/距離が短い場合何もしない
    t.addEventListener('touchend', () => {
      if (startX > moveX && startX > moveX + dist && this.slide.current !== this.slide.length) {
        // 右から左にスワイプ
        this.slide.current += 1
        this.slideChange()
      } else if (startX < moveX && startX + dist < moveX && this.slide.current !== 1) {
        // 左から右にスワイプ
        this.slide.current += -1 // 次の写真番号を代入
        this.slideChange()
      }
    })
  }
}
